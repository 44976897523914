@font-face {
  font-family: 'VisbyRoundCFMedium';
  src: local('VisbyRoundCFMedium'), url(./fonts/VisbyRoundCF-Medium.otf) format('OpenType');
}

@font-face {
  font-family: 'VisbyRoundCFDemiBold';
  src: local('VisbyRoundCFDemiBold'), url(./fonts/VisbyRoundCF-DemiBold.otf) format('OpenType');
  /* font-weight: bold; */
}


body {
  margin: 0;
  font-family: 'VisbyRoundCFMedium'
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
